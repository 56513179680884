<template>
	<div class="store-page__container" :class="storeTheme">
		<div ref="pageWrapperRef" v-if="isLoaded" class="white_page_wrapper store-theme-page" :class="storeTheme">
			<div v-if="isDesktop" class="store-theme" :class="storeTheme">
				<Header
					:full_name="user.full_name"
					:username="user.username"
					:userBio="user.bio"
					:user_image="$optimizeImage(user.user_image, { width: 500 })"
					:storeTheme="storeTheme"
					:socialIcons="user.data.socials"
				/>
			</div>

			<div class="store-content">
				<NotificationsToast />
				<div class="store-content__width">
					<transition-group name="fade-transform" mode="out-in">
						<div key="basic" class="page-container relative">
							<transition-group name="fade-transform" tag="div" appear mode="out-in">
								<div v-if="!isFirst" class="pages-block page-highlight-topbar py-3" key="top-banner-text" :style="userBackground">
									<TipTapViewer
										v-if="pageData.data.top_banner_text"
										:value="pageData.data.top_banner_text"
										class="pages-block mb-5 px-5-mod"
									/>
									<span v-else>
										<h3>WAIT!</h3>
										<p>
											Your Order Is Not Yet Complete!<br />
											<span v-if="product.price.sale_amount_available && product.price.sale_amount > 0"
												>Here’s a ONE TIME DISCOUNT!</span
											>
										</p>
									</span>
								</div>
								<Banner
									class="mb-3 pages-block"
									:title="product.title"
									:image="product.image"
									:background-color="userPrimaryColor"
									key="banner"
								/>
								<Price
									v-if="!paymentDisabled"
									class="pages-block px-5-mod mb-3"
									:currency="user.data.currency"
									:amount="product.price.amount"
									:sale-amount="product.price.sale_amount_available ? product.price.sale_amount : 0"
									:accent-color="userSecondaryColor"
									:interval="product.price.interval"
									key="price"
								/>
								<div v-else class="payment-restricted" key="payment_restricted">
									<Icon class="icon-warning" name="warning" :scale="1" :yoffset="-2" />
									<span>Product coming soon!</span>
								</div>
								<TipTapViewer class="pages-block px-5-mod description" :value="product.description" key="description"></TipTapViewer>
								<client-only>
									<UserReviews v-if="reviews && reviews.length" :reviews="reviews" class="pages-block" key="user-reviews" />
								</client-only>
								<div
									v-if="!pageData.data.bottom_title && pageData.data.payment_title"
									class="pages-block payments px-5-mod text-center"
									key="bottom-title-payment-title"
								>
									<h4>
										<span :style="{ color: userPrimaryColor }">{{ pageData.data.payment_title }}</span>
									</h4>
								</div>
								<div
									v-if="pageData.data.bottom_title"
									class="pages-block payments px-5-mod text-center"
									key="bottom-title-payment-title"
								>
									<h4>
										<span :style="{ color: userPrimaryColor }">{{ pageData.data.bottom_title }}</span>
									</h4>
								</div>
								<client-only>
									<component
										v-bind:is="calendarInstance"
										v-if="pageData.type === 'meeting' && fcpComplete"
										class="pages-block orient-horizontal px-5-mod"
										:primary-color="userPrimaryColor"
										:slug="product.meeting.slug"
										:username="user.username"
										@time-selected="bookingTimeSelected"
										key="calendar"
									/>
									<component
										v-bind:is="webinarCalendarInstance"
										v-if="pageData.type === pageTypes.WEBINAR && fcpComplete"
										class="pages-block orient-horizontal px-5-mod"
										:primary-color="userPrimaryColor"
										:slug="product.webinar.slug"
										:username="user.username"
										@time-selected="sessionTimeSelected"
										key="webinar-calendar"
									/>
								</client-only>
								<Form
									ref="inputForm"
									:show-base="isFirst"
									:show-message="showMessageInForm"
									:questions="questions"
									:cached-question-answers="cachedQuestionAnswers"
									:fcp-complete="fcpComplete"
									:showAddress="isCollectingAddress"
									@setFanDetails="setFanDetails"
									@setAnswers="setAnswers"
									@setNote="setNote"
									@formIsValid="setFanDetailsValid"
									class="pages-block"
									key="form"
								/>
							</transition-group>

							<!-- card payment -->
							<transition name="fade-transform" appear mode="out-in">
								<div
									v-if="grossAmountPreDiscount && product.price.discount_codes_available"
									:key="activePage"
									class="pages-block px-5-mod mb-5"
									key="discount-code"
								>
									<h3 class="mb-3 section-title">Discount Code</h3>
									<div
										v-if="discount_code_accepted"
										class="discount-code-wrapper"
										:class="{ 'content-disabled': !isPaymentGatewayDefined }"
									>
										<p>
											<span v-if="!discount_duration" class="discount-successful" :style="{ color: userPrimaryColor }"
												>Yay! You just saved {{ $formatCurrency(discount_amount, user.data.currency) }}</span
											>
											<span v-else class="discount-successful" :style="{ color: userPrimaryColor }"
												>Yay! {{ ((discount_amount / grossAmountPreDiscount) * 100).toFixed(0) }}% off for
												{{ discount_duration }} month(s)!
											</span>
											<br />
											<span class="subtext">Coupon Applied</span>
										</p>
										<span class="close-icon" @click="clearDiscountCode"><Icon class="icon-svg" name="close" :scale="1" /> </span>
									</div>
									<div v-else class="discount-code-wrapper input-box" :class="{ 'content-disabled': !isPaymentGatewayDefined }">
										<input v-model="discount_code" placeholder="Enter promo code here" @input="discount_error = ''" />
										<span v-if="discount_error && discount_code" class="close-icon" @click="clearDiscountCode"
											><Icon class="icon-svg" name="close" :scale="1"
										/></span>
										<HollowDotsSpinner
											v-else-if="discount_processing"
											:animation-duration="1000"
											:dot-size="12"
											class="d-flex align-items-center"
											:dots-num="3"
											:color="userPrimaryColor"
											style="margin-top: 8px"
										/>
										<button v-else @click="applyDiscountCode">Apply</button>
									</div>
									<small class="text-danger">{{ discount_error }}</small>
								</div> </transition
							><!-- discount Code -->

							<transition-group name="fade-transform" appear mode="out-in" v-if="!salesTaxEnabled || !isPaid">
								<div class="pages-block px-5-mod mb-5 relative" key="total-block" v-if="isPaid">
									<div class="amount-wrapper">
										<span class="d-flex justify-content-between align-items-center divide-bar w-100">
											<span class="total pr-2">Total :</span>
											<div v-if="grossAmount > 0" class="total-am pl-2" :style="{ color: userSecondaryColor }">
												{{ $formatCurrency(grossAmount, user.data.currency, 'en-US', true) }}
											</div>
											<div v-else class="total-am pl-2" :style="{ color: userSecondaryColor }">FREE</div>
										</span>
									</div>
									<div v-if="discount_duration" key="duration-block" class="text-right">
										for {{ discount_duration }} month(s),
										{{ $formatCurrency(grossAmountPreDiscount, user.data.currency) }} afterwards
									</div>
									<div v-if="product.price.interval && product.price.membership_duration" key="duration-block" class="text-right">
										Payments will automatically end after {{ product.price.membership_duration }} period(s)
									</div>
								</div>
							</transition-group>
							<transition-group name="fade-transform" appear mode="out-in" v-else>
								<SalesTax
									key="total-block-with-tax"
									class="mb-5"
									:userPrimaryColor="userPrimaryColor"
									:grossAmountPreDiscount="grossAmountPreDiscount"
									:discount_duration="discount_duration"
									:product="product"
									:currency="user.data.currency"
									:grossMultiProductAmount="grossAmount"
									:salesTax="salesTax"
								/>
							</transition-group>
							<!-- total -->
							<!-- base content -->
							<transition-group v-if="!paymentDisabled" tag="div" name="fade-transform-long" appear mode="out-in" class="px-5">
								<div id="paymentCardPositionId" ref="paymentCardPositionRef" key="card-payment-ref"></div>
								<StripePaymentElement
									ref="stripePayment"
									key="card-payment-form"
									v-if="store"
									v-show="showCard"
									class="pages-block invert boxed mt-5 mb-5"
									:fcp-complete="paymentCardViewed"
									:is-paid="isPaid"
									:page-id="pageData.page_id"
									:store="store"
									:theme="storeTheme"
									:primary-color="stripePrimaryColor"
									:secondary-color="userSecondaryColor"
									:username="user.username"
									:currency="user.data.currency || 'USD'"
									:is-form-submitting="formSubmitting"
									:show-wallet="false"
									:stripe-processing-account="stripeProcessingAccount"
									:stripeProcessingAccountType="stripeProcessingAccountType"
									@paymentData="paymentIntentReceived"
									@getCard="setCard"
									@paymentSuccess="paymentSuccess"
									@paymentError="paymentError"
									@paymentIntentId="updatePaymentIntentId"
								/>
							</transition-group>

							<transition-group name="fade-transform" appear mode="out-in" v-show="!!terms_and_conditions_enabled && isFirst">
								<div key="terms-and-conditions" class="px-5-mod">
									<TermsAndConditions
										:termsAndConditions="terms_and_conditions"
										:userPrimaryColor="userPrimaryColor"
										@sendAnalytic="sendAnalytic"
										@stateChanged="termsAndConditionUpdated"
									/>
								</div>
							</transition-group>

							<transition-group name="fade-transform" appear mode="out-in">
								<div class="pages-block px-5-mod mb-5" key="submit-block" v-if="!paymentDisabled">
									<button
										type="submit"
										class="confirm-button mb-5"
										:style="userBackground"
										:disabled="formSubmitting || paymentDisabled"
										@click="triggerSubmit"
									>
										<span class="text-center abos-loader">
											<HollowDotsSpinner
												v-if="formSubmitting"
												:animation-duration="1000"
												:dot-size="12"
												class="d-flex align-items-center"
												:dots-num="3"
												color="#ffffff"
											/>
											<span v-else class="white-clr">{{ pageData.data.submit_title || 'PURCHASE' }}</span>
										</span>
										<div class="d-none optional-icon" />
									</button>
									<button
										v-if="!isFirst"
										class="funnel-skip-button"
										:disabled="formSubmitting || paymentDisabled"
										@click="nextStep()"
									>
										I don’t want this <strong>one-time</strong> offer
									</button>
								</div> </transition-group
							><!-- total -->
							<ExternalLinks />
						</div>
					</transition-group>
				</div>
			</div>
		</div>
		<div v-else class="loader-wrapper">
			<div class="loader-block">
				<HollowDotsSpinner
					class="d-flex align-items-center"
					:animation-duration="1000"
					:dot-size="16"
					:dots-num="3"
					:color="userPrimaryColor"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from '~/stan-vue-shared/components/Header'
	import Banner from '~/stan-vue-shared/components/Banner'
	import TipTapViewer from '~/stan-vue-shared/components/TipTapViewer'
	import UserReviews from '~/stan-vue-shared/components/UserReviews'
	import StripePaymentElement from '~/stan-vue-shared/components/StripePaymentElement'
	import Price from '~/stan-vue-shared/components/Price'
	import Video from '~/stan-vue-shared/components/Video'
	import Form from '~/stan-vue-shared/components/Form'
	import Summary from '~/stan-vue-shared/components/Summary'
	import HollowDotsSpinner from '~/stan-vue-shared/components/HollowDotsSpinner'
	import { debounce } from '~/plugins/debounce'
	import TermsAndConditions from '~/components/TermsAndConditions'
	import ExternalLinks from '~/stan-vue-shared/components/ExternalLinks'
	import Icon from '~/stan-vue-shared/components/icons/Icon.vue'
	import NotificationsToast from '~/components/NotificationsToast'
	import PageComponentMixin from '~/mixins/PageComponentMixin.js'
	import SalesTax from '~/components/SalesTax'
	import { PAGE_TYPES } from '~/constants'
	import { isEmpty } from '~/stan-vue-shared/components/utils'

	const loadCaptcha = async site_key => {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script')
			const scriptTag = document.getElementsByTagName('script')[0]
			script.src = `https://www.google.com/recaptcha/enterprise.js?render=${site_key}`
			scriptTag.parentNode.insertBefore(script, scriptTag)
			script.onload = () => {
				resolve()
			}
		})
	}

	export default {
		name: 'SlugIndex',
		mixins: [PageComponentMixin],
		components: {
			Header,
			Banner,
			TipTapViewer,
			UserReviews,
			StripePaymentElement,
			Form,
			Price,
			Video,
			Summary,
			HollowDotsSpinner,
			TermsAndConditions,
			ExternalLinks,
			Icon,
			NotificationsToast,
			SalesTax,
		},
		head() {
			const title = '@' + this.user.username
			const description = 'Stan | Linksite'
			const image = this.$optimizeImage(this.user.user_image, { width: 500 })

			return {
				titleTemplate: title,
				title,
				meta: [
					{
						hid: 'description',
						name: 'description',
						content: description,
					},
					{ hid: 'og:title', property: 'og:title', content: title },
					{
						hid: 'og:description',
						property: 'og:description',
						content: description,
					},
					{
						hid: 'og:url',
						property: 'og:url',
						content: `${this.$config.frontURL}${this.user.username}/${this.$route.params.slug}`,
					},
					{
						hid: 'og:image',
						property: 'og:image',
						content: image,
					},
					{
						hid: 'twitter:title',
						name: 'twitter:title',
						content: title,
					},
					{
						hid: 'twitter:description',
						name: 'twitter:description',
						content: description,
					},
					{
						hid: 'twitter:image',
						name: 'twitter:image',
						content: image,
					},
				],
				link: [
					{ rel: 'icon', type: 'image/x-icon', href: this.$optimizeImage(this.user.user_image, { width: 16, height: 16, format: 'ico' }) },
				],
			}
		},
		computed: {
			calendarInstance() {
				// let comp = 'Calendar'
				if (process.client && this.pageData.type === 'meeting' && this.loadAsynCcomponents) {
					// return () => import(`~/components/${comp}`)
					return () => import(`~/stan-vue-shared/components/Calendar`)
				} else {
					return ''
				}
			},
			webinarCalendarInstance() {
				if (process.client && this.pageData.type === PAGE_TYPES.WEBINAR && this.loadAsynCcomponents) {
					return () => import(`~/stan-vue-shared/components/WebinarCalendar`)
				} else {
					return ''
				}
			},
			userBackground() {
				return { background: this.userPrimaryColor }
			},
			pageData() {
				return this.store.pages[this.activePage]
			},
			product() {
				return this.pageData.data.product
			},
			isFirst() {
				return this.activePage === 0
			},
			questions() {
				return this.product.questions || []
			},
			isLast() {
				return this.activePage === this.store.pages.length - 1
			},
			grossAmount() {
				let grossAmount = this.grossAmountPreDiscount

				if (this.discount_code && this.discount_code_accepted) {
					grossAmount = Math.round(grossAmount * ((100 - this.discount_value) / 100) * 100) / 100
				}
				return grossAmount
			},
			grossAmountPreDiscount() {
				let grossAmount = 0
				if (this.product.price) {
					grossAmount =
						this.product.price.sale_amount_available && this.product.price.sale_amount > 0
							? this.product.price.sale_amount
							: this.product.price.amount
				}
				return grossAmount
			},

			isPaid() {
				return this.grossAmount > 0
			},
			showMessageInForm() {
				return this.formMessageProducts.includes(this.pageData.type)
			},
			showCard() {
				return (
					this.isPaid &&
					!this.purchasedProducts.find(product => {
						return (
							(product.price.sale_amount_available && product.price.sale_amount > 0) ||
							(!product.price.sale_amount_available && product.price.amount > 0)
						)
					})
				)
			},
			reviews() {
				if (this.product.reviews && this.product.reviews.length > 0) {
					return this.product.reviews
				}
				return []
			},
			purchaseIntentPayload() {
				const sendData = {
					page_id: this.pageData.page_id,
					transaction_origin: this.paymentIntent?.transaction_origin || 'user',
					charge_type: this.paymentIntent?.charge_type || 'destination',
					email: this.fanDetails.email,
					name: this.fanDetails.name,
					note: this.fanDetails.note,
					phone_number: this.fanDetails.phone_number,
					description: this.product.title + ' <> ' + this.fanDetails.name,
					payment_gateway: 'stripe',
					stripe_processing_account_type: this.stripeProcessingAccountType,
				}

				if (this.isPaid) {
					sendData.payment_intent_id = this.paymentIntent.id
				}

				if (this.pageData.type === 'meeting' && this.bookingTime) {
					const startDateTime = new Date(this.bookingTime.date.replace(/-/g, '/') + ' ' + this.bookingTime.startTime)
					const endDateTime = new Date(startDateTime.getTime() + this.bookingDuration * 60000)
					sendData.meeting = {
						start: this.generateTimeString(startDateTime),
						end: this.generateTimeString(endDateTime),
						timezone: this.bookingTime.timezone,
					}
				}

				if (this.pageData.type === PAGE_TYPES.WEBINAR && this.sessionTimeSlot) {
					const startDateTime = new Date(this.sessionTimeSlot.startDateTime.replace(/-/g, '/'))
					const endDateTime = new Date(this.sessionTimeSlot.endDateTime.replace(/-/g, '/'))
					sendData.meeting = {
						start: this.generateTimeString(startDateTime),
						end: this.generateTimeString(endDateTime),
						timezone: this.sessionTimeSlot.timezone,
					}
				}

				sendData.questions = this.questions.map((question, index) => {
					this.cachedQuestionAnswers[question.content] = this.fanDetails.answers[index]
					return { question: question.content, answer: this.fanDetails.answers[index] }
				})

				if (this.discount_code && this.discount_code_accepted) {
					sendData.discount_code = this.discount_code
				}

				if (this.terms_and_conditions_enabled) {
					sendData.agreed_to_terms_and_conditions = this.isTermsAndConditionChecked
				}

				if (this.paymentIntent?.subscription) {
					sendData.subscription_id = this.paymentIntent?.subscription
				}

				if (this.salesTaxEnabled) {
					sendData.sales_tax_id = this.salesTax?.id
					sendData.billing_address = this.salesTax?.address
					sendData.sales_tax_breakdown = {
						inclusive: this.salesTax?.tax_breakdown?.inclusive,
						tax_rate_detail_country: this.salesTax?.tax_breakdown?.tax_rate_details?.country,
						tax_rate_detail_percentage_decimal: this.salesTax?.tax_breakdown?.tax_rate_details?.percentage_decimal,
						tax_rate_detail_state: this.salesTax?.tax_breakdown?.tax_rate_details?.state,
						tax_rate_detail_tax_type: this.salesTax?.tax_breakdown?.tax_rate_details?.tax_type,
						taxability_reason: this.salesTax?.tax_breakdown?.taxability_reason,
					}
				}

				return sendData
			},
			terms_and_conditions_enabled() {
				return !!this.user.data.terms_and_conditions?.enabled
			},
			terms_and_conditions() {
				return !!this.terms_and_conditions_enabled ? this.user.data.terms_and_conditions.htmlText : undefined
			},
			paymentDisabled() {
				return this.isPaid && this.user.payment_restricted.funnel
			},
			salesTaxEnabled() {
				return !!this.stripeTaxCapabilities?.data?.tax_enabled && !this.fromValidAffiliateShareLink && this.isPaid
			},
			discount_amount() {
				return this.grossAmountPreDiscount - this.grossAmount
			},
			stripeProcessingAccount() {
				return this.user.stripe_processing_account_api_key
			},
			stripeProcessingAccountType() {
				return this.user.stripe_processing_account_type
			},
			isProcessingMarketplace() {
				return this.user.stripe_processing_account_type === 'marketplace'
			},
			isPaymentGatewayDefined() {
				return this.paymentIntent?.id && this.paymentIntent?.id !== ''
			},
		},
		data() {
			return {
				isDesktop: false,
				isLoaded: false,
				activePage: 0,
				store: null,
				user: null,
				formSubmitting: false,
				updatedPaymentIntentId: '',
				paymentIntent: {},
				purchasedProducts: [],
				discount_code: '',
				discount_processing: false,
				discount_code_accepted: false,
				discount_value: 0, // percentage
				discount_duration: 0,
				discount_error: '',
				paymentCardViewed: false,
				fanDetails: {},
				formMessageProducts: ['meeting', 'fulfillment'],
				recurringProducts: ['membership'],
				paymentCardPosition: 0,
				documentBottom: 0,
				bookingTime: false,
				bookingDuration: 0,
				sessionTimeSlot: undefined,
				card: false,
				stripe: false,
				loadAsynCcomponents: false,
				isFanDetailsValid: false,
				cachedQuestionAnswers: {},
				country: 'US',
				fcpComplete: false,
				auth_token: null,
				isTermsAndConditionChecked: false,
				stripePrimaryColor: '',
				stripeTone1Color: '',
				stripeTaxCapabilities: {},
				salesTax: {},
				flattenPostalAddress: '',
				isCollectingAddress: false,
				pageTypes: PAGE_TYPES,
			}
		},
		async asyncData({ params, $axios, store, redirect, query, app }) {
			const username = params.username
			const slug = params.slug

			async function getStores(username, slug) {
				try {
					// return  await $axios.$get('v1/funnels', {
					return await $axios.$get('v1/stores', {
						baseURL: process.env.NUXT_ENV_PYTHON_API,
						params: { username: username, slug: slug, type: 'funnel' },
					})
				} catch (err) {
					console.error('error get funnel data: ', err)
					return null
				}
			}

			const fres = await getStores(username, slug)

			if (fres === null) {
				redirect(`/${username}`)
				return {}
			}

			if (!fres.store || !fres.store.pages || !fres.store.pages.length) {
				redirect(`/${username}`)
				return {}
			}

			if (fres.user && fres.user.color_code) {
				store.commit('setUserPrimaryColor', fres.user.color_code)
			}

			let user = {}
			if (fres.user) {
				user = fres.user
				store.commit('setUser', user)
			}

			return { store: fres.store, user }
		},
		async mounted() {
			this.isLoaded = true
			await loadCaptcha(this.$config.captcha_site_key)

			this.resize = () => {
				this.isDesktop = window.innerWidth >= 1344
			}
			this.resize()
			window.addEventListener('resize', this.resize)
			window.addEventListener('click', this.checkInterceptLink)

			this.$nextTick(() => {
				const themeColors = this.$addThemeColorsToDocument(
					this.storeTheme,
					this.userPrimaryColor,
					this.userSecondaryColor,
					this.userPrimaryFont
				)
			})

			this.$axios
				.$get('v1/integrations/stripe/account-capabilities', {
					baseURL: process.env.NUXT_ENV_PYTHON_API,
					params: { page_id: this.pageData.page_id },
				})
				.then(response => {
					this.stripeTaxCapabilities = response
					this.isCollectingAddress = this.salesTaxEnabled && this.isFirst
				})

			const analyticsGoogle = async user => {
				if (this.user.data?.analytics?.google?.token) {
					const vueGtag = await import('vue-gtag')
					const gToken = this.user.data.analytics.google.token
					vueGtag.setOptions({
						config: { id: gToken },
					})
					vueGtag.bootstrap().then(this.$gtag)
				}
				return true
			}
			!isEmpty(this.tags) &&
				this.sendAnalytic('store-visit-tagged', {
					type: 'store_home',
				})
			this.sendAnalytic('store-visit', { type: 'store_home' })
			this.trackPage()
			// TODO: Confirm usage
			;(async () => {
				let resp = await Promise.all([analyticsGoogle(this.user)])
			})()
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.resize)
		},
		updated: debounce(function () {
			if (!this.fcpComplete) {
				this.$nextTick(() => {
					this.fcpComplete = true
					setTimeout(() => {
						this.paymentCardViewed = true
					}, 500)
				})
			}

			if (!this.loadAsynCcomponents) {
				this.$nextTick(() => {
					this.loadAsynCcomponents = true
				})
			}
		}, 200),
		methods: {
			generateTimeString(date) {
				const year = date.getFullYear()
				const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date)
				const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
				const time = new Intl.DateTimeFormat('en-US-u-hc-h23', { hour: '2-digit', minute: 'numeric', second: 'numeric' }).format(date)
				return `${year}-${month}-${day} ${time}`
			},
			trackPage() {
				this.sendAnalytic('store-page-view', {
					order: this.activePage,
					showReviews: !!this.pageData.data?.reviews?.length,
					// remove below once ready
					page_number: this.activePage,
				})
			},
			setFanDetails(fanDetails) {
				if (this.isFirst) {
					this.fanDetails = fanDetails
				}
			},
			setAnswers(answers) {
				this.fanDetails.answers = answers
			},
			setNote(note) {
				this.fanDetails.note = note
			},
			setFanDetailsValid(formIsValid) {
				if (this.isFirst) {
					this.isFanDetailsValid = formIsValid && !!this.fanDetails.email && !!this.fanDetails.name
				}
			},

			checkInterceptLink(event) {
				var target = event.target.closest('a[href]')
				if (target) {
					event.preventDefault()
					const openNewPage = target.getAttribute('target') === '_blank'
					this.openLink(target.getAttribute('href'), openNewPage)
				}
			},
			openLink(url, newPage = false) {
				// Check if stan affiliate and append username
				let linkUrl = null
				try {
					linkUrl = new URL(url)
				} catch (e) {
					return (window.location.href = url)
				}
				const frontendURI = process.env.NUXT_ENV_FRONT_URL.replace('www.', '')
				if (linkUrl && linkUrl.href.includes(frontendURI) && !!linkUrl.searchParams.get('al')) {
					linkUrl.searchParams.set('su', this.user.username)

					// Analytics to determine what information we have available for affiliate codes to be used in determining the optimal solution
					this.sendAnalytic('affiliate_code_link_clicked_from_funnel_page', {
						session_last_visit_store: this.$sessionStorage.getItem('LastVisitStore'),
						query_username: this.$route.query?.su || null,
						cookie_ref: this.$cookies.get('ref') || null,
						document_ref: window.document.referrer || null,
						affiliate_code: linkUrl.searchParams.get('al'),
						affiliated_username: linkUrl.searchParams.get('su'),
					})
				}

				if (newPage) {
					return window.open(linkUrl, '_blank')
				}
				window.location.href = linkUrl
			},
			sendAnalytic(name, additional_props = {}) {
				const pageData = this.pageData
				const product = pageData.data.product
				const analyticsPropsUse = {
					storeId: this.store.store_id,
					storeType: this.store.type,
					pageId: pageData.page_id,
					pageType: pageData.type,
					order: this.activePage,
					productId: product.id,
					productTitle: product.title,
					price: this.grossAmount,
					fanName: this.fanDetails.name,
					fanEmail: this.fanDetails.email,
					// remove below once ready
					product_title: product.title,
					product_price: this.grossAmount,
					product_type: product.type,
					fan_name: this.fanDetails.name,
					fan_email: this.fanDetails.email,
					store_id: this.store.store_id,
					page_id: pageData.page_id,
					product_id: product.id,
					page: this.activePage,
					status: 'completed',
					source: 'funnel',
					site: 'linkSite',
					...additional_props,
				}

				this.$stanAnalytics(name, {
					meta: { username: this.user.username },
					props: analyticsPropsUse,
				})
			},
			bookingTimeSelected(timeSlot) {
				this.bookingTime = timeSlot.slot
				this.bookingDuration = timeSlot.duration
			},
			sessionTimeSelected(timeSlot) {
				this.sessionTimeSlot = timeSlot
			},
			isFormValid() {
				// Validate form
				if (this.$refs.inputForm.formIssues().length > 0) {
					return false
				}
				// Warning for discount code
				if (this.discount_code && !this.discount_code_accepted && !this.discount_error) {
					return false
				}
				return true
			},
			canSubmit() {
				let submittable = this.isFormValid() && this.isFanDetailsValid && this.fanDetails.email && !this.formSubmitting
				if (this.pageData.type === 'meeting') {
					submittable = submittable && this.bookingTime && this.bookingTime.date && this.bookingTime.startTime && this.bookingTime.endTime
				}
				if (this.pageData.type === PAGE_TYPES.WEBINAR) submittable = submittable && this.sessionTimeSlot

				if (this.user.data.terms_and_conditions?.enabled && this.isFirst) {
					submittable = submittable && this.isTermsAndConditionChecked
				}
				if (this.salesTaxEnabled && !this.salesTax?.id) {
					this.$refs.inputForm.validateAddress()
					submittable = false
				}
				return submittable
			},
			setCard(card, stripe) {
				if (!this.card) {
					this.card = card
				}
				if (!this.stripe) {
					this.stripe = stripe
				}
			},
			createPaymentIntent(pageData = this.pageData, token) {
				const payload = { page_id: pageData.page_id, token: token, stripe_processing_account_type: this.stripeProcessingAccountType }
				this.$axios
					.post('v1/integrations/stripe/create-payment-intent', payload, { baseURL: process.env.NUXT_ENV_PYTHON_API })
					.then(async response => {
						this.paymentIntent = response.data
						const payload = {
							...this.purchaseIntentPayload,
							page_id: pageData.page_id,
						}
						if (!this.card) {
							this.$refs.stripePayment.createCardField(this.paymentIntent)
						}

						this.$refs.stripePayment.updatePaymentData(payload)
						this.firstPaymentIntentSent = true
					})
					.catch(error => {
						this.paymentIntent = null
						this.formSubmitting = false
						if (error.request.status == 401) {
							this.$notify({
								group: '1',
								title: 'Oops! Something went wrong.',
								text: 'Try refreshing or using a different browser.',
								type: 'error',
							})
						} else {
							this.$notify({
								group: '1',
								title: '@' + this.$route.params.username + ' has not yet setup their payment flow!',
								text: 'Please let them know to setup their Stan payouts so they can start accepting your payments!',
								type: 'error',
							})
						}
						this.$sentry.captureException(new Error('Could not create purchase-intent'), {
							extra: {
								error,
								purchaseIntentPayload: this.purchaseIntentPayload,
							},
						})
					})
			},
			updatePaymentIntentId(paymentIntentId) {
				this.updatedPaymentIntentId = paymentIntentId
			},
			applyDiscountCode() {
				const findInvalidCharacter = /[^A-Za-z0-9]+/
				this.discount_processing = true
				if (this.discount_code && this.isPaymentGatewayDefined) {
					if (findInvalidCharacter.test(this.discount_code)) {
						this.discount_error = 'Invalid discount code'
						this.discount_processing = false
					} else {
						const payload = {
							discount_code: this.discount_code,
							page_id: this.pageData.page_id,
							username: this.$route.params.username,
							payment_intent_id: this.paymentIntent.id,
							payment_gateway: 'stripe',
						}
						return this.$axios
							.post('v1/pages/discount-code', payload, { baseURL: process.env.NUXT_ENV_PYTHON_API })
							.then(response => {
								const { data } = response
								this.discount_code_accepted = true

								this.discount_type = data.discount_type
								this.discount_value = data.discount_value

								this.discount_duration = data.discount_duration
								this.discount_processing = false
								this.calculateSalesTax()
								return data
							})
							.catch(error => {
								const message =
									error.response && error.response.data ? error.response.data.message : 'Something went wrong. Try again later'
								this.discount_error = message
								this.discount_processing = false
							})
					}
				} else {
					this.discount_error = 'Did you forget to include your discount code?'
					this.discount_processing = false
				}
			},
			clearDiscountCode() {
				this.discount_code = ''
				this.discount_error = ''
				this.discount_code_accepted = false
				this.discount_value = 0
				this.discount_duration = 0
				this.calculateSalesTax()
			},
			processPayment() {
				this.$refs.stripePayment.processPayment(this.paymentIntent.secret, this.card, this.stripe)
			},
			paymentSuccess() {
				const pageData = this.pageData
				if (this.auth_token) {
					this.$cookies.set('auth_token', this.auth_token)
					this.$store.commit('setAuthToken', this.auth_token)
				}

				const product = pageData.data.product

				const analyticsProps = {}
				if (this.discount_code && this.discount_code_accepted) {
					product.price.sale_amount_available = true
					analyticsProps.discount_code = this.discount_code
					analyticsProps.discountCode = this.discount_code
				}

				if (pageData.type === 'meeting') {
					product.meeting_id = product.id
					product.start = this.bookingTime.date + ' ' + this.bookingTime.startTime
					product.end = this.bookingTime.date + ' ' + this.bookingTime.endTime
					product.timezone = this.bookingTime.timezone
				}

				if (pageData.type === PAGE_TYPES.WEBINAR) {
					product.meeting_id = product.id
					product.start = this.sessionTimeSlot.startDateTime
					product.end = this.sessionTimeSlot.endDateTime
					product.timezone = this.sessionTimeSlot.timezone
				}

				if (pageData.type === 'course') {
					product.course_url = `/course/${product.course.slug}?store=${this.store.slug}`
				}

				this.purchasedProducts.push(product)
				this.sendAnalytic('purchase', analyticsProps)
				!isEmpty(this.tags) && this.sendAnalytic('purchase-tagged', analyticsProps)
				this.nextStep()
			},
			paymentError(errorData) {
				this.formSubmitting = false
				this.$notify({
					group: '1',
					title: 'Payment error',
					text: errorData.message || 'Please complete the payment form!',
					type: 'error',
				})
			},
			paymentIntentReceived(paymentIntent) {
				this.paymentIntent = paymentIntent.data
			},
			async triggerSubmit() {
				if (this.canSubmit()) {
					this.formSubmitting = true
					if (this.isPaid) {
						try {
							if (this.discount_code && this.discount_code_accepted) {
								await this.applyDiscountCode()
							}
							await this.$refs.stripePayment.updatePaymentData(this.purchaseIntentPayload)
							this.$nextTick(async () => {
								const response = await this.$axios.post('v1/orders/purchase-intent', this.purchaseIntentPayload, {
									baseURL: process.env.NUXT_ENV_PYTHON_API,
								})

								this.auth_token = response.data.auth_token
								if (this.pageData.type === 'course' && response.data.course_fan_slug)
									this.pageData.data.product.course.slug = response.data.course_fan_slug
								this.processPayment()
							})
						} catch (error) {
							this.formSubmitting = false
							const errorMessageData = {
								group: '1',
								title: 'Something went wrong',
								text: 'Please try again later. If problem persists, reach out to us at <a href="mailto:friends@stanwith.me">friends@stanwith.me</a>',
								type: 'error',
							}
							if (error.response) {
								if (error.response.data.code === 'purchase_temp_block') {
									errorMessageData.title = 'Currently Unavailable'
									errorMessageData.text =
										'This product is on a temporary hold due to limited availability, please try again in 3 minutes.'
								}
							}
							this.$notify(errorMessageData)
							this.$sentry.captureException(new Error('Could not create purchase-intent'), {
								extra: {
									error,
									purchaseIntentPayload: this.purchaseIntentPayload,
								},
							})
						}
					} else {
						this.$axios
							.post('v1/pages/completed/' + this.pageData.type, this.purchaseIntentPayload, {
								baseURL: process.env.NUXT_ENV_PYTHON_API,
							})
							.then(response => {
								this.auth_token = response.data.auth_token
								if (this.pageData.type === 'course' && response.data.course_fan_slug)
									this.pageData.data.product.course.slug = response.data.course_fan_slug
								this.paymentSuccess()
							})
					}
				} else {
					let title = 'Form incomplete'
					let text = 'Please complete all required fields!'
					let type = 'error'
					if (!!this.terms_and_conditions && !this.isTermsAndConditionChecked && this.isFirst) {
						text = 'Please accept Terms and Conditions!'
						title = 'Form incomplete'
					}
					if (this.discount_code && !this.discount_code_accepted && !this.discount_error) {
						text = 'You have an unapplied discount code.'
						type = 'warn'
					}
					if (this.pageData.type === PAGE_TYPES.WEBINAR && !this.sessionTimeSlot) {
						if (!this.$refs['webinar-calendar'].hasAvailableSession) {
							title = 'No available times'
							text = 'Please check back later for upcoming events!'
						} else if (this.isFanDetailsValid) {
							title = 'Please choose a time'
							text = 'Select a time slot in order to finalize your booking.'
						}
					}
					this.$notify({
						group: '1',
						title: title,
						text: text,
						type: type,
					})
				}
			},
			nextStep() {
				if (this.activePage === this.store.pages.length - 1) {
					this.$store.commit('setPurchasedProducts', this.purchasedProducts)
					this.$router.push('/' + this.$route.params.username + '/success')
				} else {
					this.activePage++
				}
				this.isCollectingAddress = false
				this.formSubmitting = false
			},
			termsAndConditionUpdated(val) {
				this.isTermsAndConditionChecked = val
			},
			calculateSalesTax() {
				this.salesTax = {}
				if (this.salesTaxEnabled) {
					const payload = {
						page_id: this.pageData.page_id,
						country: this.fanDetails.address.country,
						state: this.fanDetails.address.state,
						city: this.fanDetails.address.city,
						postal_code: this.fanDetails.address.postal_code,
						street: this.fanDetails.address.street,
						amount: this.grossAmount,
						currency: this.user.data.currency,
					}
					this.$axios
						.$post('v1/integrations/calculate-sales-tax', payload, {
							baseURL: process.env.NUXT_ENV_PYTHON_API,
						})
						.then(response => {
							this.salesTax = response
						})
				}
			},
		},
		watch: {
			activePage(newValue) {
				this.trackPage()
				this.clearDiscountCode()
				this.salesTax = {}
				this.$nextTick(() => {
					setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 500)
					if (this.isPaid) {
						grecaptcha.enterprise.ready(async () => {
							const token = await grecaptcha.enterprise.execute(this.$config.captcha_site_key, {
								action: 'CreatePaymentIntent',
							})
							this.createPaymentIntent(this.pageData, token)

							if (!this.flattenPostalAddress && this.salesTaxEnabled) {
								this.isCollectingAddress = true
							}
							// re-calculate the tax based on new data
							this.calculateSalesTax()
						})
					}
				})
			},
			fanDetails: {
				deep: true,
				handler(newVal) {
					if (this.salesTaxEnabled && newVal.address) {
						if (
							newVal.address?.city !== '' &&
							newVal.address?.state !== '' &&
							newVal.address?.postal_code !== '' &&
							newVal.address?.country !== '' &&
							this.flattenPostalAddress !==
								`${newVal.address.street} ${newVal.address.city} ${newVal.address.state} ${newVal.address.postal_code} ${newVal.address.country}`
						) {
							// Calculate Tax
							this.calculateSalesTax()
							this.flattenPostalAddress = `${newVal.address.street} ${newVal.address.city} ${newVal.address.state} ${newVal.address.postal_code} ${newVal.address.country}`
						}
					}
				},
			},
		},
	}
</script>
<style lang="scss" scoped>
	@import '~/stan-vue-shared/assets/styles/themes/index.scss';
	@import '~/stan-vue-shared/assets/styles/themes/store-page.scss';
	@import '~/stan-vue-shared/assets/styles/themes/responsive/index.scss';
	@import '~/stan-vue-shared/assets/styles/themes/pages/responsive/index.scss';
</style>
<style lang="scss">
	.grecaptcha-badge {
		display: none !important;
	}
	.content-disabled {
		pointer-events: none;
		opacity: 0.4;
	}
	.ProseMirror {
		iframe {
			border-radius: 14px !important;
		}
	}
</style>
